import type { ExerciseId, RuleId } from "./Level"

export const Boxes = [
  "NEVER", // the rule has never been drawn, it has a high priority
  "ERROR", // the user failed the last time the rule was drawn
  "1", // the user doesn't know the rule very well
  "2",
  "3",
  "4",
  "5", // the user knows the rule well, the next time it is drawn will be a critical question
  "LAST", // the rule is know, it should not be drawn unless there is only one unknown rule left
] as const
export type Box = typeof Boxes[number]

export const RuleStatuses = [
  "KNOWN", // the user probably knew the rule before (no errors)
  "LEARNED", // the user learned the rule (some errors)
  "DIFFICULT", // the user is having difficulty with this rule (>=5 errors)
  "LEARNING", // still learning the rule
] as const
export type RuleStatus = typeof RuleStatuses[number]

export const BoxProgression: { [K in Box]: number } = {
  NEVER: 0,
  ERROR: 0,
  1: 0.28,
  2: 0.45,
  3: 0.55,
  4: 0.72,
  5: 0.88,
  LAST: 1,
}

// Common Interface for Training and Initial and NextEvaluation without timestamp
export interface Interaction {
  /** in milliseconds */
  timestamp: number
  ruleId: RuleId
  exerciseId: ExerciseId
  correct: boolean
  metadata: Record<string, any>
}

export type EngineInteraction = Omit<Interaction, "metadata">

export interface ExerciseData {
  id: ExerciseId
  difficulty: 1 | 2 | 3
}

export interface RuleData {
  box: Box
  exercises: ExerciseData[]
  timesDrawn: number
  timesCorrect: number
  timesError: number
  coolDownUntil: number
  intensiveCountPerRule: number
  maxBox: Box
}

export interface RuleMetadata {
  critical: boolean
  clue: boolean
  explanation: boolean
  status: RuleStatus
  intensive: boolean
  intensiveCount?: number
}

export type RecordInteractionResult = Pick<EngineState, "allLearned"> & {
  learned: number
  progression: number
}

export interface EngineState {
  data: Record<RuleId, RuleData>
  history: EngineInteraction[]
  errors: number
  draws: number // not all draws result in interactions, since some draws are unsuccessful
  errorHysteresis: boolean
  allLearned: boolean
}

export const getRuleIdsByBox = (engineState: EngineState, box: Box): RuleId[] =>
  Object.entries(engineState.data)
    .map(([ruleId, ruleData]) => (ruleData.box === box ? parseInt(ruleId, 10) : undefined))
    .filter((id: RuleId | undefined): id is RuleId => id !== undefined)
