// noinspection JSUnusedGlobalSymbols

import type { AxiosResponse } from "axios"
import _ from "lodash"

import type { BOSession, LearnerExaminationResult, SessionId } from "./Evaluation"
import { apiUrl, axios, logger } from "./local"
import type { PracticeTestProgression } from "./Progression"
import type { EvaluationId, LevelId, ScenarioId } from "./Scenario"

export const getUserExaminationsByEval = async (
  userId: string,
  evalId: EvaluationId,
  auth: { headers: { Authorization: string } },
  onError?: (...args: any) => any,
): Promise<LearnerExaminationResult | undefined> => {
  try {
    const rawExaminations = await axios.get<
      any,
      AxiosResponse<{ results: Array<Omit<BOSession, "id"> & { sessionId: SessionId }> }>
    >(`${apiUrl}/evaluation/evaluation/${evalId}/session`, {
      params: { userId },
      ...auth,
    })
    // TODO: Remove the filter on the userId once implemented
    return _(rawExaminations.data.results)
      .flatMap(elem => elem.learners)
      .filter(learner => learner.userId === userId && learner.status === "graded")
      .orderBy(["finishExaminationDate"], "desc")
      .head()
  } catch (e) {
    onError?.(e)
    return undefined
  }
}

export const getPreviousPracticeTest = async (
  scenarioId: ScenarioId,
  levelId?: LevelId,
): Promise<
  Pick<PracticeTestProgression, "clientStart" | "progress" | "trainingBlockId"> | undefined
> => {
  try {
    const { progress, trainingBlockId, clientStart } = (
      await axios.get<any, AxiosResponse<PracticeTestProgression>>(
        `${apiUrl}/training/progress/level/${levelId}?scenarioId=${scenarioId}`,
      )
    ).data
    return {
      clientStart,
      progress,
      trainingBlockId,
    }
  } catch (e) {
    logger("no previous practice test", e)
    return undefined
  }
}
