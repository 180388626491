import type { AuthParams } from "./auth"
import type { ResourceSummary, UserWithLicenses } from "./License"
import { apiUrl, axios } from "./local"
import type { Paginated } from "./Progression"
import type { ResourceId } from "./Scenario"
import type { UniverseId } from "./User"

export const getLicensePacks = async (
  universeId: UniverseId,
  authParams?: AuthParams,
): Promise<ResourceSummary[]> => {
  const result = await axios.get<ResourceSummary[]>(
    `${apiUrl}/universe/${universeId}/licenses/packs`,
    { headers: authParams?.headers },
  )
  return result.data
}

export const getLicenseUsers = async (
  universeId: UniverseId,
  authParams?: AuthParams,
  resourceId?: ResourceId,
  params?: any,
): Promise<UserWithLicenses[]> => {
  const result = await axios.get<Paginated<UserWithLicenses>>(
    `${apiUrl}/universe/${universeId}/licenses/learners`,
    { headers: authParams?.headers, params: { resourceId, ...params } },
  )
  return result.data.results
}
