import { throwIfApiError } from "./ApiError"
import type { BackDivision, DivisionId, Universe } from "./BackDivision"
import { apiUrl, axios } from "./local"
import type { UniverseId } from "./User"

export const getUniverse = async (universeId: UniverseId, auth?: string): Promise<Universe> =>
  throwIfApiError(
    await axios.get<Universe>(`${apiUrl}/universe/universe/${universeId}`, {
      headers: {
        Authorization: auth ?? "",
      },
    }),
  )

export const getDivision = async (divisionId: DivisionId, auth?: string): Promise<BackDivision> =>
  throwIfApiError(
    await axios.get<BackDivision>(`${apiUrl}/universe/division/${divisionId}`, {
      headers: {
        Authorization: auth ?? "",
      },
    }),
  )
