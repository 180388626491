import type { ResponseType } from "axios"
import CryptoJS from "crypto-js"
import _ from "lodash"

import { logger, nocache } from "./local"

// obfuscated the key "RW5jcnlwdCBvciBvYmZ1cw==" with https://anseki.github.io/gnirts/
// @ts-ignore
// eslint-disable-next-line
const key = CryptoJS.enc.Base64.parse((34).toString(36).toLowerCase().split('').map(function(c){return String.fromCharCode(c.charCodeAt()+(-39))}).join('')+(13).toString(36).toLowerCase().split('').map(function(m){return String.fromCharCode(m.charCodeAt()+(-13))}).join('')+(12054025885).toString(36).toLowerCase()+(702).toString(36).toLowerCase().split('').map(function(B){return String.fromCharCode(B.charCodeAt()+(-39))}).join('')+(function(){var d=Array.prototype.slice.call(arguments),e=d.shift();return d.reverse().map(function(T,h){return String.fromCharCode(T-e-59-h)}).join('')})(50,238,217,166,206,224,203,231,178,216,209,227)+(13).toString(36).toLowerCase().split('').map(function(S){return String.fromCharCode(S.charCodeAt()+(-39))}).join('')+(function(){var f=Array.prototype.slice.call(arguments),I=f.shift();return f.reverse().map(function(U,o){return String.fromCharCode(U-I-22-o)}).join('')})(31,114))
const iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000")

const transformResponse = (response: any, ...rest: unknown[]): any => {
  if (!nocache && !_.isTypedArray(response)) {
    // from cache
    // logger("from cache")
    return response
  }

  let text

  try {
    text = CryptoJS.AES.decrypt(
      CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.lib.WordArray.create(response) }),
      key,
      { iv },
    ).toString(CryptoJS.enc.Utf8)

    // eslint-disable-next-line no-console
    // logger(text)
    // logger("from net")

    return JSON.parse(text)
  } catch (e) {
    logger(e)
    logger({ response: response.toString(), rest })
    return response
  }
}

export default {
  transformResponse,
  responseType: "arraybuffer" as ResponseType,
  timeout: 5000,
}
