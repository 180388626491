// noinspection JSUnusedGlobalSymbols

import JwtDecode from "jwt-decode"

import { throwIfApiError } from "./ApiError"
import { apiUrl, axios, logger } from "./local"
import type { BackMemo } from "./Memo"
import type { CurrentUser, Jwt, User, UserId } from "./User"

export const getCurrentUser = async (auth?: string): Promise<CurrentUser> =>
  throwIfApiError(
    await axios.get(`${apiUrl}/universe/user/current`, {
      headers: {
        Authorization: auth ?? "",
      },
    }),
  )

export const getUserInfos = async (
  userId: UserId,
  auth?: string,
): Promise<User & { inscriptionDate: string }> =>
  throwIfApiError(
    await axios.get(`${apiUrl}/universe/user/${userId}`, {
      headers: {
        Authorization: auth ?? "",
      },
    }),
  )

export const getMemos = async (auth?: string): Promise<BackMemo[]> =>
  throwIfApiError(
    await axios.get<{ results: BackMemo[] }>(`${apiUrl}/training/memo`, {
      headers: {
        Authorization: auth ?? "",
      },
    }),
  ).results

export const decodeJwt = (auth?: string): Jwt | undefined => {
  try {
    return auth ? JwtDecode(auth.slice(7)) : undefined
  } catch (e) {
    logger(e)
    return undefined
  }
}
