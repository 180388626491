/* eslint-disable no-console */
// noinspection JSUnusedGlobalSymbols

import _ from "lodash"

import type { LevelProgression, ModuleProgression, Step } from "./Progression"
import type { LevelId } from "./Scenario"

export const mergeGraphs = (
  graphs: Step[][],
  {
    timeBuckets = 20,
    totalNumber,
    weights, // there may be more weights
  }: { timeBuckets?: number; totalNumber?: number; weights?: number[] } = {},
): Step[] | undefined => {
  const sortedSteps = _(graphs)
    .flatten()
    .orderBy(s => s.date)
    .value()

  // Get the first and date for the desired graph
  const firstTimestamp = _.first(sortedSteps)?.date ?? 0
  const lastTimestamp = _.last(sortedSteps)?.date ?? 0
  const bucketWidth = (lastTimestamp - firstTimestamp) / timeBuckets

  if (bucketWidth <= 0) {
    return undefined
  }

  const merged = _.times(timeBuckets, i => ({
    date: firstTimestamp + i * bucketWidth,
    percentage: 0,
  }))

  merged.forEach(bucket => {
    bucket.percentage =
      _(graphs)
        .map(
          (steps, index) =>
            (_(steps)
              .orderBy(s => s.date)
              .findLast(s => s.date <= bucket.date + bucketWidth)?.percentage ?? 0) *
            (weights?.[index] ?? 1),
        )
        .sum() / (totalNumber ?? (_.sum(weights) || graphs.length))
    bucket.date = bucket.date + bucketWidth / 2
  })

  return merged
}

export const mergeLevelGraphs = (
  progression: Record<LevelId, LevelProgression> | undefined,
  nbrLevels: number,
): Step[] | undefined =>
  mergeGraphs(
    _(progression)
      .map(l => l.graph)
      .compact()
      .value(),
    { totalNumber: nbrLevels },
  )

export const mergeModuleGraphs = (
  progression: ModuleProgression[] | undefined,
  weights?: number[],
  totalNumber?: number,
): Step[] | undefined =>
  mergeGraphs(
    _(progression)
      .map(l => _.orderBy(l.completionGraph, step => step.date))
      .compact()
      .value(),
    { weights, totalNumber },
  )
