// noinspection JSUnusedGlobalSymbols

import _ from "lodash"

import type { RuleData } from "./EngineState"
import type { Level, LevelDetails, LevelRevision, RuleId, RuleMin } from "./Level"
import { fetchLevel } from "./ScenarioFunctions"

export const toEngineRule = (rule: RuleMin): { id: RuleId; exercises: RuleData["exercises"] } => ({
  id: rule.id,
  exercises: rule.exercises.map(e => ({
    id: e.id,
    difficulty: e.difficulty ?? 2,
  })),
})

export function getLevelsForRevision(levels: Level[], revisionLevel: LevelRevision): Level[] {
  const levelIndex = levels.findIndex(l => l.id === revisionLevel.id)
  return _.takeRightWhile(levels.slice(0, levelIndex), l => l.type === "static")
}

export const getRevisionLevelDetails = async (
  /* info of the revision level = id, type, maxRulePerStep */
  revisionLevel: LevelRevision,
  /* all the levels in the module */
  levels: Level[],
  revisionRulesIds: RuleId[],
): Promise<LevelDetails> => {
  /* we get the relevant levels
  if it's a revision level, all the levels since the last
  if it's a final revision level, all the levels of the module */
  const levelsForRevision =
    revisionLevel.type === "revision" ? getLevelsForRevision(levels, revisionLevel) : levels

  const levelsRules = await Promise.all(
    levelsForRevision.map(async l => {
      const levelDetail = await fetchLevel(undefined, l.id)
      return levelDetail.rules.filter(rule => revisionRulesIds.includes(rule.id))
    }),
  )

  return {
    ...revisionLevel,
    title: "",
    description: "",
    rules: levelsRules.flat(),
  } as unknown as LevelDetails
}
