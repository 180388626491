// noinspection JSUnusedGlobalSymbols
import type { DivisionId } from "./BackDivision"
import type { EngineInteraction, Interaction } from "./EngineState"
import type {
  ExaminationGrade,
  ExaminationId,
  SessionId,
  TrainingBlockId,
  UniqueCopyId,
} from "./Evaluation"
import type { Rule } from "./Level"
import type {
  BackEvaluationInteraction,
  BackInitialEvaluationInteraction,
  BackInteraction,
  ProgressionData,
} from "./Progression"
import type {
  EvaluationId,
  InitialEvaluationInteraction,
  LevelId,
  ScenarioId,
  TrainingInteraction,
} from "./Scenario"

export type LevelStatus = "open" | "completed" | "uncompleted"

export type EvaluationType = "initial_evaluation" | "next_evaluation" | "practice_test"
export type CalculationMethod = "bonusenabled" | "bonusdisabled"

export interface Evaluation {
  evaluationId: EvaluationId
  name: string
  timeLimit: number
  scenarioId: ScenarioId
  contentId: LevelId
  divisionId: DivisionId
  calculationMethod: CalculationMethod
  gradeIncluded: boolean
  type: EvaluationType
  sessionIds: SessionId[]
  rules: Rule[]
  evaluatedLearner: number
  averageGrade: number
  totalLearners: number
  /** data used in BO, not converted to timestamp at the moment */
  startDate: string
  /** data used in BO, not converted to timestamp at the moment */
  dueDate: string
  duration: number
}

export type BOEvaluation = Omit<Evaluation, "evaluationId"> & { id: EvaluationId }

// Provider
export interface LevelOrPracticeTestParams {
  interactions?: EngineInteraction[]
  uniqueCopyId: UniqueCopyId
  status?: LevelStatus
  grade?: ExaminationGrade
}

// Server/Bff
export interface ClosedLevelResult {
  progression?: ProgressionData
  score?: number
}

// Interactions interfaces for storage

export interface CommonInteractionParams {
  interaction: Interaction
  evaluationType?: EvaluationType
}

export interface TrainingInteractionParams extends CommonInteractionParams {
  isEnd?: boolean
  isRuleNewlyAcquired?: boolean
  trainingBlockId?: TrainingBlockId
}

export interface EvaluationInteractionParams extends CommonInteractionParams {
  uniqueCopyId: ExaminationId
}

export type BackModuleId = string
export type BackLevelId = string
export type BackRuleId = string

// NextEvaluation or First common field from engine
export const toBackNextEvaluationInteraction = (
  interaction: InitialEvaluationInteraction | TrainingInteraction,
): BackEvaluationInteraction => ({
  /** timestamp is created in the back end, if not created here */
  ruleId: String(interaction.ruleId),
  exerciseId: String(interaction.exerciseId),
  correct: interaction.correct,
  metadata: interaction.metadata,
})

export const toBackInteraction = (interaction: TrainingInteraction): BackInteraction => ({
  ...toBackNextEvaluationInteraction(interaction),
  timestamp: new Date(interaction.timestamp).toISOString(),
  stepId: String(interaction.stepId),
  levelId: String(interaction.levelId),
  moduleId: String(interaction.moduleId),
  scenarioId: String(interaction.scenarioId),
  trainingBlockId: interaction.trainingBlockId ?? "fallback-that-should-not-happen",
})

export const toBackInitialEvaluationInteraction = (
  interaction: InitialEvaluationInteraction,
): BackInitialEvaluationInteraction => ({
  ...toBackNextEvaluationInteraction(interaction),
  initialEvaluationBlockId: interaction.initialEvaluationBlockId ?? "",
})
