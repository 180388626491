import type { AxiosInstance } from "axios"

export interface LocalConfig {
  contentUrl: string
  apiUrl: string
  logger: (message: any, ...meta: any[]) => any
  axios: AxiosInstance
  axiosCache: AxiosInstance
  nocache: boolean
}

let contentUrl: string
let apiUrl: string
let logger: (message: any, ...meta: any[]) => any
let axios: AxiosInstance
let axiosCache: AxiosInstance
let nocache: boolean

export const localInit = (config: LocalConfig): void => {
  ;({ contentUrl, apiUrl, logger, axios, axiosCache, nocache } = config)
}

export { contentUrl, apiUrl, logger, axios, axiosCache, nocache }
