// noinspection JSUnusedGlobalSymbols

import _ from "lodash"

import type { BaseEngine, EngineDeps, Logger } from "./engine"
import { getDurationStatic } from "./engine"
import type { EngineInteraction, RuleData, RuleMetadata } from "./EngineState"
import type { EvaluationEngineState } from "./EvaluationEngineState"
import type { ExerciseId, RuleId } from "./Level"
import { parseInt10 } from "./util"

/* initialize engine state with exercises */
const initEvaluationEngine = (
  rules: Array<{ id: RuleId; exercises: RuleData["exercises"] }>,
): EvaluationEngineState => {
  const truncExercises = rules.map(rule => ({
    id: rule.id,
    exercises: _.sampleSize(rule.exercises, 2),
  }))

  const exercises = _.zipObject(
    truncExercises.map(r => r.id),
    truncExercises.map(r =>
      r.exercises.map(e => ({
        id: e.id,
        difficulty: e.difficulty,
      })),
    ),
  )

  return {
    data: exercises,
    history: [],
  }
}

export interface EvaluationEngine extends BaseEngine<RuleMetadata> {
  getRuleExercisesAvailable: (ruleId: RuleId) => RuleData["exercises"]
  getScore: () => { score: number; ratio: number }
}

// eslint-disable-next-line no-underscore-dangle
const makeEvaluationEngine_ = (
  deps?: EngineDeps,
): {
  init: (rules: Array<{ id: RuleId; exercises: RuleData["exercises"] }>) => EvaluationEngine
} => {
  const logger: Logger = deps?.logger ?? ((): void => {})

  // TODO remove the need to fake RuleMetadata
  const init = (
    rules: Array<{ id: RuleId; exercises: RuleData["exercises"] }>,
  ): EvaluationEngine => {
    const engineState: EvaluationEngineState = initEvaluationEngine(rules)

    const getRuleExercisesAvailable = (ruleId: RuleId): RuleData["exercises"] => {
      // Get list of exercises for a given ruleId
      const ruleExercises = engineState.data[ruleId]

      if (ruleExercises === undefined) {
        throw new Error(`rule ${ruleId} does not exist in engineState`)
      }

      const exercisesAlreadyPicked = engineState.history
        .filter(it => it.ruleId === ruleId)
        .map(it => it.exerciseId)

      return ruleExercises.filter(exercise => !exercisesAlreadyPicked.includes(exercise.id))
    }

    // Get current progression (how far into the test the user is)
    const getProgression = (): number =>
      engineState.history.reduce((acc, curr, index, history) => {
        acc += curr.correct
          ? 1
          : history.slice(0, index).some(it => it.ruleId === curr.ruleId)
          ? 1
          : 2
        return acc
      }, 0)

    // Get current score - ratio of rules for which two correct answers have been given
    const getScore = (): { score: number; ratio: number } => {
      const score = _(
        engineState.history.reduce(
          (acc, curr) => {
            if (curr.correct) {
              acc[curr.ruleId]++
            }
            return acc
          },
          _.mapValues(engineState.data, () => 0),
        ),
      )
        .values()
        .sumBy(v => (v >= 2 ? 1 : 0))
      return {
        score,
        ratio: score / Object.keys(engineState.data).length,
      }
    }

    /** get the aggregated training duration, by grouping interactions into 2-minute intervals */
    const getDuration = (): {
      sessions: number
      duration: number
      sessionsDetail: Array<[number, number]>
    } => getDurationStatic(engineState.history)

    const recordInteraction = (
      interaction: EngineInteraction,
    ): { progression: number; allLearned: boolean; learned: number } => {
      engineState.history.push(interaction)
      const numberOfExercisesForEvaluation = _.sumBy(
        Object.values(engineState?.data),
        ex => ex.length,
      )
      const progression = getProgression()
      return {
        progression: progression / numberOfExercisesForEvaluation,
        allLearned: progression / numberOfExercisesForEvaluation >= 1,
        learned: progression,
      }
    }

    const drawRuleAndExercise = ():
      | ({ ruleId: RuleId; exerciseId: ExerciseId } & RuleMetadata)
      | Record<string, never> => {
      // Create counter to know for each rule, how many exercise have we done
      const ruleExercisesCounter: { [key: string]: number } = Object.keys(engineState.data).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: 0,
        }),
        {},
      )
      // Increment counter
      engineState.history.map(it => {
        if (!it.correct) {
          ruleExercisesCounter[it.ruleId] = 2
        } else {
          ruleExercisesCounter[it.ruleId] += 1
        }
      })
      // Get array of ruleId available to pick a rule from it
      const rulesAvailable = Object.entries(ruleExercisesCounter)
        .map(([id, counter]) => (counter < 2 ? id : null))
        .filter(rule => rule != null)

      const lastRuleId = _.last(engineState.history)?.ruleId

      // Exclude one correct single response from the list of candidates
      const excludeRuleId = _(engineState.history)
        .find(i => i.correct)
        ?.ruleId?.toString()

      // Pick a random ruleId, trying to pick a different rule than the last pick
      let ruleIdString = _(rulesAvailable)
        .without(excludeRuleId ?? "-1")
        .sampleSize(2)
        .find(rid => parseInt(rid ?? "-1", 10) !== lastRuleId)

      if (ruleIdString == null && !_.isEmpty(rulesAvailable)) {
        ruleIdString = _(rulesAvailable).find(rid => parseInt(rid ?? "-1", 10) !== lastRuleId)
        if (ruleIdString == null) {
          logger("Can't find a non consecutive rule", rulesAvailable, excludeRuleId, dump())
          ruleIdString = _.sample(rulesAvailable)
        }
      }

      if (ruleIdString == null) {
        return {}
      }

      const ruleId: RuleId = parseInt(ruleIdString, 10)

      // Get exercises list according to ruleId
      const ruleExercises = getRuleExercisesAvailable(ruleId)

      // Pick a random exercise
      const exercise = _.sample(ruleExercises)

      logger("drawRuleAndExercise", ruleId, exercise?.id, exercise?.difficulty)

      if (!exercise) {
        return {}
      }

      return {
        ruleId,
        exerciseId: exercise.id,
        clue: false,
        critical: false,
        explanation: false,
        intensive: false,
        status: "LEARNING",
      }
    }

    const getInteractions = (): EngineInteraction[] => engineState.history

    const getRulesAndExercises = (): Array<{ id: number; ruleId: number }> =>
      _.flatMap(engineState.data, (ruleData, ruleId) =>
        _.map(ruleData, ex => ({ id: ex.id, ruleId: parseInt(ruleId, 10) })),
      )

    const getRuleStatuses = (): { masteredRules: RuleId[]; unmasteredRules: RuleId[] } => {
      const results = _.reduce(
        engineState.history,
        (acc, curr) => ({
          ...acc,
          [curr.ruleId]: curr.correct ? (acc[curr.ruleId] ?? 0) + 1 : -1000,
        }),
        _.mapValues(engineState.data, _.constant(0)),
      )

      const [masteredRules, unmasteredRules] = _(results)
        .toPairs()
        .partition(([_ruleId, s]) => s >= 2)
        .value()
        .map(a => a.map(([ruleId]) => parseInt10(ruleId)))

      return {
        masteredRules,
        unmasteredRules,
      }
    }

    const dump = (): Record<string, any> => ({
      history: _(engineState.history)
        .map(i => `${i.ruleId}-${i.correct ? "✅" : "❌"}`)
        .join(" | "),
      progression: `progression: ${getProgression()} score: ${JSON.stringify(getScore())}`,
    })

    return {
      drawRuleAndExercise,
      recordInteraction,
      getProgression,
      getScore,
      getRuleStatuses,
      getInteractions,
      getRulesAndExercises,
      getRuleExercisesAvailable,
      dump,
      getDuration,
      // @ts-ignore for testing
      engineState,
    }
  }

  return { init }
}

export interface EvaluationEngineCreator extends ReturnType<typeof makeEvaluationEngine_> {}
export const makeEvaluationEngine: (
  ...opts: Parameters<typeof makeEvaluationEngine_>
) => EvaluationEngineCreator = makeEvaluationEngine_
