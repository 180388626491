import type { AxiosResponse } from "axios"
import _ from "lodash"

import { logger } from "./local"

export interface ApiError {
  code: string
  message?: string
}

export type ApiResponse<T> = AxiosResponse<T> | AxiosResponse<ApiError>

export function isApiError(result: AxiosResponse): result is AxiosResponse<ApiError> {
  if (result.status >= 200 && result.status < 300) {
    return false
  }
  // TODO remove IsEmpty when the response is fixed
  // return "code" in result.data || "errorCode" in result.data
  return _.isEmpty(result.data) ? false : _.isObject(result.data) && "code" in result.data
}

// noinspection JSUnusedGlobalSymbols
export const validateStatus = (status: number): boolean => status < 500 && status !== 401

export function throwIfApiError<T>(result: ApiResponse<T>): T {
  if (isApiError(result)) {
    logger("API error", result.data)
    throw result.data
  }
  return result.data
}
