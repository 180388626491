/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import _ from "lodash"

import type {
  Box,
  EngineInteraction,
  EngineState,
  RecordInteractionResult,
  RuleData,
  RuleMetadata,
} from "./EngineState"
// eslint-disable-next-line no-duplicate-imports
import { Boxes, BoxProgression, getRuleIdsByBox } from "./EngineState"
import type { ExerciseId, RuleId } from "./Level"
import type { RuleProgression, Step } from "./Progression"
import { roundPercent } from "./ProgressionFunctions"

export type Logger = typeof console.log

export interface EngineDeps {
  logger?: Logger
}
/** initialize engine state with a set of rules (from a level) */
const initEngine = (
  rules: Array<{ id: RuleId; exercises: RuleData["exercises"] }>,
): EngineState => ({
  data: _.zipObject(
    rules.map(r => r.id),
    rules.map(r => ({
      box: "NEVER",
      exercises: _.shuffle(
        r.exercises.map(e => ({
          id: e.id,
          difficulty: e.difficulty,
        })),
      ),
      timesDrawn: 0,
      timesCorrect: 0,
      timesError: 0,
      coolDownUntil: 0,
      intensiveCountPerRule: 0,
      maxBox: "NEVER",
    })),
  ),
  history: [],
  errors: 0,
  draws: 0,
  errorHysteresis: false,
  allLearned: false,
})

const CONSECUTIVE_INTERACTION_DELAY = 2 * 60 * 1000

export interface BaseEngine<RM = Record<string, never>> {
  drawRuleAndExercise: () =>
    | ({ ruleId: RuleId; exerciseId: ExerciseId } & RM)
    | Record<string, never>
  recordInteraction: (
    interaction: EngineInteraction,
    withProgression?: boolean,
  ) => RecordInteractionResult
  getProgression: () => number
  getInteractions: () => EngineInteraction[]
  getRuleStatuses: () => { masteredRules: RuleId[]; unmasteredRules: RuleId[] }
  getRulesAndExercises: () => Array<{ ruleId: RuleId; id: ExerciseId }>
  getDuration: () => { sessions: number; duration: number; sessionsDetail: Array<[number, number]> }
  dump: (withProgression?: boolean, withProgressionGraph?: boolean) => Record<string, any>
}

export interface TrainingEngine extends BaseEngine<RuleMetadata> {
  drawRule: (retries?: number) => RuleId | undefined
  getRuleMetadata: (ruleId: RuleId) => RuleMetadata
  getRuleProgressions: () => Record<RuleId, RuleProgression>
  getEngineProgressionGraph: (timeBuckets: number) => Step[] | undefined
}

export const getDurationStatic = (
  interactions: EngineInteraction[],
): {
  sessions: number
  duration: number
  sessionsDetail: Array<[number, number]>
} => {
  const sortedInteractions = _.sortBy(interactions, i => i.timestamp)
  const intervals = sortedInteractions.reduce<Array<[number, number]>>(
    (acc, interaction, index, history) => {
      if (
        index === 0 ||
        interaction.timestamp - history[index - 1].timestamp > CONSECUTIVE_INTERACTION_DELAY
      ) {
        acc.push([interaction.timestamp, interaction.timestamp])
      } else {
        acc[acc.length - 1][1] = interaction.timestamp
      }
      return acc
    },
    [],
  )
  // logger(intervals)
  return {
    sessions: intervals.length,
    duration: _.sumBy(intervals, interval =>
      Math.max(interval[1] - interval[0], CONSECUTIVE_INTERACTION_DELAY / 2),
    ),
    sessionsDetail: intervals,
  }
}

// eslint-disable-next-line no-underscore-dangle
const makeEngine_ = (
  deps?: EngineDeps,
): {
  getProgressionGraph: (
    graphRules: Array<{ id: RuleId; exercises: RuleData["exercises"] }>,
    interactions: EngineInteraction[],
    timeBuckets: number,
  ) => Step[] | undefined
  init: (rules: Array<{ id: RuleId; exercises: RuleData["exercises"] }>) => TrainingEngine
} => {
  const logger: Logger = deps?.logger ?? ((): void => {})

  const init = (rules: Array<{ id: RuleId; exercises: RuleData["exercises"] }>): TrainingEngine => {
    const engineState: EngineState = initEngine(rules)

    const getRuleData = (ruleId: RuleId): RuleData => {
      const res = engineState.data[ruleId]
      if (res === undefined) {
        throw new Error(`rule ${ruleId} does not exist in engineState`)
      }
      return res
    }

    /** pick a rule from the list based on engine state */
    const drawRule = (retries = 10): RuleId | undefined => {
      if (retries === 0) {
        return undefined
      }

      engineState.draws++

      const preReturn = (ruleId: RuleId): RuleId => {
        const ruleData = getRuleData(ruleId)
        ruleData.timesDrawn++
        ruleData.coolDownUntil = engineState.draws + 5

        logger("drawn", ruleId)

        return ruleId
      }

      const ruleWeights: Record<RuleId, number> = _.mapValues(engineState.data, ruleData =>
        // DrawSameKeyPointAfterAtLeastFiveTurns
        ruleData.coolDownUntil > engineState.draws || ruleData.box === "LAST" ? 0 : 1,
      )

      // DrawFromErrorBox
      const errorBox = getRuleIdsByBox(engineState, "ERROR")
      logger("errorBox", errorBox)
      if (engineState.errorHysteresis && errorBox.length < 5) {
        engineState.errorHysteresis = false
      }
      if (errorBox.length >= 7 || engineState.errorHysteresis) {
        engineState.errorHysteresis = true
        const error = _.sample(errorBox.filter(ruleId => ruleWeights[ruleId] === 1))

        if (error !== undefined) {
          logger("ErrorHysteresis", error)
          return preReturn(error)
        }
      }

      // DrawExerciseFromNeverDrawnBoxFirstAndInOrder
      const neverDrawnBox = getRuleIdsByBox(engineState, "NEVER")
      _(neverDrawnBox)
        .slice(0, 1)
        .forEach(ruleId => {
          ruleWeights[ruleId] *= _.size(neverDrawnBox)
        })
      _(neverDrawnBox)
        .slice(1)
        .forEach(ruleId => {
          ruleWeights[ruleId] = 0
        })

      // SmartProbabilityDrawRule
      if (_.size(engineState.data) > 20) {
        _(engineState.data).forEach((ruleData, ruleId) => {
          const boxIndex = Boxes.indexOf(ruleData.box) - Boxes.indexOf("1")
          if (boxIndex >= 0) {
            ruleWeights[parseInt(ruleId, 10)] *= Math.pow(2, Boxes.length - 3 - boxIndex)
          }
        })
      }

      logger(
        "ruleWeights",
        _(ruleWeights)
          .map((w, id) => `${id}-${w}`)
          .join(" "),
      )

      const rulesToSample = Object.entries(ruleWeights).flatMap(([k, v]) =>
        _.times(v, () => parseInt(k, 10)),
      )

      logger("rulesToSample", rulesToSample.join(" "))

      const drawn = _.sample(rulesToSample)

      if (drawn === undefined) {
        logger("Couldn't draw, retrying")
        return drawRule(retries - 1)
      }

      return preReturn(drawn)
    }

    const getRuleMetadata = (ruleId: RuleId): RuleMetadata => {
      const rule = getRuleData(ruleId)
      const ruleHistory = engineState.history.filter(i => i.ruleId === ruleId)

      const box = rule.box

      const isIntensive =
        ruleHistory.length >= 2 &&
        _(ruleHistory)
          .slice(-2)
          .every(r => !r.correct)

      if (isIntensive) {
        engineState.data[ruleId].intensiveCountPerRule =
          engineState.data[ruleId].intensiveCountPerRule + 1
      }

      return {
        critical: box === Boxes[Boxes.indexOf("LAST") - 1],
        clue: box === "ERROR",
        explanation: box === "ERROR" || box === "NEVER",
        status:
          box === "LAST" && rule.timesError === 0
            ? "KNOWN"
            : box === "LAST" && rule.timesError > 0
            ? "LEARNED"
            : rule.timesError >= 5
            ? "DIFFICULT"
            : "LEARNING",
        intensive: isIntensive,
        intensiveCount: engineState.data[ruleId].intensiveCountPerRule,
      }
    }

    // TODO: no test coverage for this function, beware
    const getRuleStatuses = (): { masteredRules: RuleId[]; unmasteredRules: RuleId[] } => {
      const [masteredRules, unmasteredRules] = _(engineState.data)
        .map((ruleData, ruleId) => ({ ruleId: parseInt(ruleId, 10), box: ruleData.box }))
        .partition(r => r.box === "LAST")
        .value()

      return {
        masteredRules: _(masteredRules)
          .map(mr => mr.ruleId)
          .compact()
          .value(),
        unmasteredRules: _(unmasteredRules)
          .map(umr => umr.ruleId)
          .compact()
          .value(),
      }
    }

    const drawRuleAndExercise = ():
      | ({ ruleId: RuleId; exerciseId: ExerciseId } & RuleMetadata)
      | Record<string, never> => {
      const ruleId = drawRule()

      if (ruleId === undefined) {
        return {}
      }
      const ruleData = getRuleData(ruleId)

      const ruleExercises = ruleData.exercises
      const metadata = getRuleMetadata(ruleId)

      const availableExercises = metadata.critical
        ? ruleExercises.filter(e => e.difficulty === 3)
        : metadata.status === "DIFFICULT"
        ? ruleExercises.filter(e => e.difficulty === 1)
        : ruleExercises

      const availableExercisesNotEmpty = _.isEmpty(availableExercises)
        ? ruleExercises
        : availableExercises
      const exercise =
        availableExercisesNotEmpty[ruleData.timesDrawn % availableExercisesNotEmpty.length]

      logger("exercise", exercise?.id, exercise?.difficulty)

      if (!exercise) {
        return {}
      }

      return { ruleId, exerciseId: exercise.id, ...metadata }
    }

    /** update engine state based on interaction */
    const recordInteraction = (interaction: EngineInteraction): RecordInteractionResult => {
      engineState.history.push(interaction)

      const ruleData = getRuleData(interaction.ruleId)
      if (!interaction.correct) {
        engineState.errors++

        ruleData.timesError++

        // once a rule is in LAST, further training does not remove it from LAST
        if (ruleData.box !== "LAST") {
          // PromoteToErrorBoxAfterBadAnswer
          ruleData.box = "ERROR"
        }
      } else {
        ruleData.timesCorrect++

        const boxIndex =
          // PromoteFromNeverDrawnBoxAfterGoodAnswer
          ruleData.box === "NEVER"
            ? Boxes.indexOf("LAST") - 2
            : // PromoteFromErrorBoxAfterGoodAnswer
            ruleData.box === "ERROR"
            ? Math.max(Boxes.indexOf("LAST") - 2 - ruleData.timesError, Boxes.indexOf("1"))
            : // PromoteToNextBoxAfterGoodAnswer
              Math.min(Boxes.indexOf(ruleData.box) + 1, Boxes.indexOf("LAST"))

        const v = Boxes[boxIndex]
        if (v !== undefined) {
          ruleData.box = v
        } else {
          throw new Error(`boxIndex ${boxIndex} is out of bounds 0-${Boxes.length - 1}`)
        }
      }

      if (Boxes.indexOf(ruleData.box) > Boxes.indexOf(ruleData.maxBox)) {
        ruleData.maxBox = ruleData.box
      }

      engineState.allLearned = Object.values(engineState.data).every(r => r.box === "LAST")

      const progression = updateProgression()

      return {
        allLearned: engineState.allLearned,
        learned: getRuleIdsByBox(engineState, "LAST").length,
        progression,
      }
    }

    /** compute progression percentage based on engine state */
    const updateProgression = (): number => {
      const numberPerBox = Object.values(engineState.data).reduce(
        (acc, { maxBox: box }) => {
          acc[box]++
          return acc
        },
        { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, ERROR: 0, LAST: 0, NEVER: 0 } as Record<
          Box,
          number
        >,
      )

      const numberPerBoxEntries = Object.entries(numberPerBox) as Array<[Box, number]>
      const avg = numberPerBoxEntries.reduce(
        (acc, [k, v]) => {
          acc.num += v
          acc.weight += v * BoxProgression[k]
          return acc
        },
        { weight: 0, num: 0 },
      )

      const progression = avg.num === 0 ? 0 : avg.weight / avg.num

      return roundPercent(progression * 100)
    }

    /** get a histogram of progression for the current engine state */
    const getEngineProgressionGraph = (timeBuckets = 100): Step[] | undefined =>
      getProgressionGraph(
        Object.entries(engineState.data).map(([id, rule]) => ({ id: parseInt(id, 10), ...rule })),
        engineState.history,
        timeBuckets,
      )

    /** get the aggregated training duration, by grouping interactions into 2-minute intervals */
    const getDuration = (): {
      sessions: number
      duration: number
      sessionsDetail: Array<[number, number]>
    } => getDurationStatic(engineState.history)

    const getInteractions = (): EngineInteraction[] => engineState.history

    const getRulesAndExercises = (): Array<{ id: number; ruleId: number }> =>
      _.flatMap(engineState.data, (ruleData, ruleId) =>
        _.map(ruleData.exercises, ex => ({ id: ex.id, ruleId: parseInt(ruleId, 10) })),
      )

    const getRuleProgressions = (): Record<RuleId, RuleProgression> =>
      _.mapValues(engineState.data, rule => ({
        completionPercentage: roundPercent(BoxProgression[rule.box] * 100),
        maxCompletionPercentage: roundPercent(BoxProgression[rule.maxBox] * 100),
        isKnown: rule.timesError === 0,
      }))

    /** debug utility */
    const dump = (
      withProgression = false,
      withProgressionGraph = false,
    ): {
      history: string
      data: string[]
      state: string
      progression?: string
      graph?: string
    } => ({
      history: _(engineState.history)
        .map(i => `${i.ruleId}-${i.correct ? "✅" : "❌"}`)
        .join(" | "),
      data: _(engineState.data)
        .map((r, id) =>
          `${id} ${r.box} drawn ${r.timesDrawn} correct ${r.timesCorrect} ${
            r.coolDownUntil >= engineState.history.length ? "cooldown" : ""
          } ${dumpMetadata(getRuleMetadata(parseInt(id, 10)))}`.replace(/\s{2,}/g, " "),
        )
        .value(),
      state: `draws ${engineState.history.length} errors ${engineState.errors} ${
        engineState.errorHysteresis ? "errorHysteresis" : ""
      } ${engineState.allLearned ? "allKnown" : ""}`,
      progression: withProgression
        ? `progression: ${updateProgression().toFixed(0)} duration: ${(
            getDuration().duration / 1000
          ).toFixed()} s`
        : undefined,
      graph: withProgressionGraph
        ? `\nprogressionGraph: ${
            getEngineProgressionGraph(10)
              ?.map(step => step.percentage.toFixed(0))
              .join(".") ?? ""
          }`
        : undefined,
    })

    const dumpMetadata = (metadata: RuleMetadata): string =>
      `${metadata.critical ? "CRIT" : ""} ${metadata.clue ? "CLUE" : ""} ${
        metadata.explanation ? "EXPL" : ""
      } ${metadata.intensive ? "INTE" : ""} ${metadata.status}`

    return {
      drawRule,
      dump,
      getEngineProgressionGraph,
      getRuleMetadata,
      drawRuleAndExercise,
      recordInteraction,
      getProgression: updateProgression,
      getRuleStatuses,
      getRuleProgressions,
      getInteractions,
      getRulesAndExercises,
      getDuration,
      // @ts-ignore for testing
      engineState,
    }
  }

  /** compute a histogram of progression for a set of rules and interactions */
  const getProgressionGraph = (
    graphRules: Array<{ id: RuleId; exercises: RuleData["exercises"] }>,
    interactions: EngineInteraction[],
    timeBuckets = 100,
  ): Step[] | undefined => {
    const sortedInteractions = _.sortBy(interactions, i => i.timestamp)
    const firstTimestamp = _.first(sortedInteractions)?.timestamp ?? 0
    const lastTimestamp = _.last(sortedInteractions)?.timestamp ?? 0
    const bucketWidth = (lastTimestamp - firstTimestamp + 1) / timeBuckets

    if (bucketWidth <= 0) {
      return undefined
    }

    const graphEngine = init(graphRules)

    return sortedInteractions
      .reduce(
        (acc, i) => {
          const bucket = Math.floor((i.timestamp - firstTimestamp) / bucketWidth)
          graphEngine.recordInteraction(i)
          const progression = graphEngine.getProgression()
          acc[bucket].percentage = Math.max(acc[bucket]?.percentage ?? 0, progression)
          return acc
        },
        _.times(timeBuckets, i => ({
          date: firstTimestamp + i * bucketWidth + bucketWidth / 2,
          percentage: 0,
        })),
      )
      .map((v, i, acc) => ({
        date: v.date,
        percentage: Math.max(v.percentage, ...acc.slice(0, i).map(p => p.percentage)),
      }))
  }

  return {
    getProgressionGraph,
    init,
  }
}

export interface EngineCreator extends ReturnType<typeof makeEngine_> {}
export const makeEngine: (...opts: Parameters<typeof makeEngine_>) => EngineCreator = makeEngine_
