// noinspection JSUnusedGlobalSymbols

import type { LevelId } from "./Scenario"

export type RuleId = number
export type ExerciseId = number

export const isStatic = (level: Level): level is LevelStatic =>
  level.type === "static" || level.type === "practiceTest"

export interface LevelStatic {
  id: LevelId
  type: "static"
  title: string
  description: string
  rulesNbr: number
  originalId: string
}

export interface LevelPracticeTest extends Omit<LevelStatic, "type" | "originalId"> {
  type: "practiceTest"
  timeLimit: number
}

export interface LevelRevision extends Omit<LevelStatic, "type" | "rulesNbr"> {
  type: "revision" | "finalRevision"
  maxRulePerStep: number
}

export type Level = LevelStatic | LevelPracticeTest | LevelRevision

export type LevelType = Level["type"]

export type LevelDetails = Omit<LevelStatic, "rulesNbr" | "originalId"> & {
  rules: Rule[]
}

export type LevelDetailsMin = Omit<LevelStatic, "rulesNbr" | "originalId"> & {
  rules: RuleMin[]
}

export type RevisionLevelDetails = Omit<LevelDetails, "type"> & { type: LevelRevision["type"] }
export type RevisionLevelDetailsMin = Omit<LevelDetailsMin, "type"> & {
  type: LevelRevision["type"]
}

export type Result =
  | "correct"
  | "wrong"
  | "error/no-mistake"
  | "error/missed-mistake"
  | "error/wrong-word"

export interface WordBlock {
  text: string
  italic?: boolean
  bold?: boolean
  clue?: boolean
  mistake?: boolean
  correction?: boolean
  after?: string
  before?: string
  breakAfter?: boolean
  noSpaceAfter?: boolean
}

export interface PickOutOneWordBlock {
  text: string
  isAnswer?: boolean
  doNotPick?: boolean
  /** True if the word was picked from another exercise. Not in the data itself, it's added later when a word is picked */
  foreign?: boolean
}

export type ColumnNumber = 1 | 2

export interface DragAndDropWord {
  /** Index in the word array */
  id: string
  content: string
  correctColumn: ColumnNumber
}

interface BaseExercise {
  id: ExerciseId
  index?: number
  type: ExerciseType
  instruction?: string
  difficulty?: 1 | 2 | 3
}

export interface PickOutOneExercise extends BaseExercise {
  type: "pick_out_one"
  qcType: "double_choices" | "triple_choices" | "quad_choices"
  /** Number of wrong choices to display with the correct one */
  distractorsCount: number
  words: PickOutOneWordBlock[]
  sentence?: string
  /** Each correction is an array of WordBlocks */
  corrections?: WordBlock[][]
  /** probably string with HTML */
  comment?: string
  /** optional sound for duo/trio exercise */
  answerSound?: string
  exerciseSound?: string
}

export interface ClickOnMistakeExercise extends BaseExercise {
  type: "click_on_mistake"
  sentence: WordBlock[]
  /** Each correction is an array of WordBlocks */
  corrections?: WordBlock[][]
  hasMistake: boolean
}

export interface ClickOnWordExercise extends BaseExercise {
  type: "click_on_word"
  sentence: WordBlock[]
  /** Each correction is an array of WordBlocks */
  corrections?: WordBlock[][]
}

export interface PickOutNearestExercise extends Omit<PickOutOneExercise, "type"> {
  type: "pick_out_nearest"
  /** "verb" | "commonName" | ..., all possible types not known */
  wordType: string
  instructionWord: string
  exampleSentence?: string
  definitions?: Definition[]
  hasSound?: boolean
}

export interface Definition {
  definition: string
  example?: string
}

export interface DragAndDropExercise extends BaseExercise {
  type: "drag_and_drop"
  /** Number of proposals to pick in total */
  displayCount: number
  columns: ColumnContent[]
}

export interface EmailContentData {
  text: string
  clue?: boolean
  mistake?: boolean
}

export type EmailHeaderField = "from" | "to" | "cc" | "cci" | "object" | "attachment"

export interface EmailContent {
  body: EmailContentData[]
  header: {
    attachment: EmailContentData[]
    from: EmailContentData[]
    object: EmailContentData[]
    to: EmailContentData[]
    cc: EmailContentData[]
    cci: EmailContentData[]
  }
}

export type HighlightedPart = "header" | "body"

export interface EmailPickOneOutExercise extends BaseExercise {
  type: "email_pick_out_one"
  corrections: WordBlock[][]
  emailContent: EmailContent
  options: EmailPickOutOneOption[]
  /** probably string with HTML */
  comment?: string
}

export interface EmailClickOnMistakeExercise extends BaseExercise {
  type: "click_on_email"
  emailContent: EmailContent & { isHighlighted: HighlightedPart }
  corrections: WordBlock[][]
  hasMistake: boolean
  /** probably string with HTML */
  comment?: string
}

export interface EmailPickOutOneOption {
  isAnswer: boolean
  text: string
}

export interface FormattedDragAndDropExercise extends Omit<DragAndDropExercise, "columns"> {
  columns: FormattedColumnContent[]
}

export interface FormattedColumnContent extends Omit<ColumnContent, "words"> {
  words: DragAndDropWord[]
}

export interface ColumnContent {
  instruction: string
  /** Words can have HTML markup */
  words: string[]
}

export type Exercise =
  | ClickOnMistakeExercise
  | PickOutOneExercise
  | ClickOnWordExercise
  | DragAndDropExercise
  | PickOutNearestExercise
  | EmailPickOneOutExercise
  | EmailClickOnMistakeExercise
export type ExerciseType = Pick<Exercise, "type">["type"]
export type ExerciseWithRuleId = Exercise & { ruleId: RuleId }
export type RuleResult = Record<RuleId, Record<ExerciseId, Result | undefined>>

export function isClickOnMistake(exercise?: Exercise): exercise is ClickOnMistakeExercise {
  return exercise?.type === "click_on_mistake"
}

export function isClickOnWord(exercise?: Exercise): exercise is ClickOnWordExercise {
  return exercise?.type === "click_on_word"
}

export function isPickOutOne(exercise?: Exercise): exercise is PickOutOneExercise {
  return exercise?.type === "pick_out_one"
}

export function isPickOutNearest(exercise?: Exercise): exercise is PickOutNearestExercise {
  return exercise?.type === "pick_out_nearest"
}

export function isDragAndDrop(exercise?: Exercise): exercise is DragAndDropExercise {
  return exercise?.type === "drag_and_drop"
}

export function isEmailPickOutOne(exercise?: Exercise): exercise is EmailPickOneOutExercise {
  return exercise?.type === "email_pick_out_one"
}

export function isClickOnEmail(exercise?: Exercise): exercise is EmailClickOnMistakeExercise {
  return exercise?.type === "click_on_email"
}

export interface Resources {
  lesson?: string
  lessonFile?: string
  videos?: string[]
}

export interface IntensiveTrainingExercise {
  /** TODO: change name of the key? */
  correcte: boolean
  sentence: string
}

export interface Rule {
  id: RuleId
  title: string
  description: string
  instruction?: string
  exercises: Exercise[]
  resources?: Resources
  /** "Précision de l'expert" - to display with the rule description */
  additionalExplanation?: string
  isConvertibleToDictation?: boolean
  /** For click on mistake exercises only */
  intensiveTrainingExercises?: IntensiveTrainingExercise[]
}

export type RuleMin = Pick<Rule, "id" | "title"> & {
  exercises: Array<Pick<BaseExercise, "id" | "difficulty">>
}
