// noinspection JSUnusedGlobalSymbols

import _ from "lodash"
import urlParse from "url-parse"

export type PartialPartial<T> = {
  [P in keyof T]?: Partial<T[P]>
}

export type PartialPartialPartial<T> = {
  [P in keyof T]?: PartialPartial<T[P]>
}

export function isEmpty<T>(a: T[] | undefined): a is [] | undefined
export function isEmpty(a: string | undefined): a is "" | undefined
export function isEmpty(a: unknown): a is undefined {
  if (typeof a === "string") {
    return a === ""
  } else if (Array.isArray(a)) {
    return (a?.length ?? 0) === 0
  }
  return a == null
}

export function isNotEmpty<T>(a: T): a is NonNullable<T> {
  // @ts-ignore
  return !isEmpty(a)
}

export function isNotNullish<T>(a: T): a is NonNullable<T> {
  return a != null
}

export function lett<V, R>(v: V | undefined | null, lambda: (vv: V) => R): R | undefined {
  if (v == null) {
    return undefined
  }

  return lambda(v)
}

export function also<V, R>(v: V | undefined | null, lambda: (vv: V) => R): V | undefined | null {
  if (v == null) {
    return v
  }

  lambda(v)

  return v
}

export const parseInt10 = (x: string): number => parseInt(x, 10)

export const allPages = (queryString: string): string => {
  const query = urlParse.qs.parse(queryString)

  _.omit(query, ["end", "start", "_page"])

  query.pageSize = "-1"

  return urlParse.qs.stringify(query)
}

export const getVersion = (packageJson: {
  version: string
  config: { hash: string; build: number | string; message: string }
}): { version: string; nbCommits: number; lastCommitId: string; lastCommitMessage: string } => {
  const { build, hash, message } = packageJson.config
  const buildNb = parseInt10(String(build))
  const nbCommits = isNaN(buildNb) ? 0 : buildNb

  return {
    version: packageJson.version.replace(/(\d*)\.(\d*)\.(\d*)/, `$1.$2.${nbCommits}`),
    nbCommits,
    lastCommitId: hash,
    lastCommitMessage: message,
  }
}

export const versions = (...v: Array<string | undefined>): string =>
  v.filter(isNotEmpty).join(" • ")
