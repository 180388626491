// noinspection JSUnusedGlobalSymbols

import _ from "lodash"

import { throwIfApiError } from "./ApiError"
import type { AuthParams } from "./auth"
import type { Interaction } from "./EngineState"
import { apiUrl, axios, logger } from "./local"
import type { BackEvaluationInteraction, BackInteraction, ScenarioProgression } from "./Progression"
import type { LevelId, Route, Scenario, ScenarioId, TrainingInteraction } from "./Scenario"
import type { PartialPartialPartial } from "./util"
import { lett, parseInt10 } from "./util"

export const on20withHalfPoints = (grade: number): number => _.round(_.round(grade * 40, 0) / 2, 1)

export const gradeOn20Improved = (gradeRatio: number, completionRatio: number): number =>
  on20withHalfPoints(gradeRatio + ((1 - gradeRatio) / 2) * completionRatio)

export const roundPercent = (percent: number): number => Math.floor(_.round(percent, 2))

export const toInteraction = (
  backInteraction: BackInteraction | BackEvaluationInteraction,
  isNextEvaluation?: boolean,
): Interaction & Pick<TrainingInteraction, "stepId" | "trainingBlockId"> => ({
  trainingBlockId: (backInteraction as BackInteraction).trainingBlockId,
  timestamp: backInteraction.timestamp
    ? new Date(backInteraction.timestamp).getTime()
    : new Date().getTime(),
  ruleId: parseInt10(backInteraction.ruleId),
  exerciseId: parseInt10(backInteraction.exerciseId),
  correct: backInteraction.correct,
  stepId: isNextEvaluation
    ? undefined
    : (backInteraction as BackInteraction).stepId
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parseInt10((backInteraction as BackInteraction).stepId!)
    : undefined,
  metadata: backInteraction.metadata,
})

export const getRoute = (
  progressionDetail: PartialPartialPartial<ScenarioProgression>,
  scenario: Scenario,
): Route | undefined => lett(getRouteIndex(progressionDetail, scenario), i => scenario.routes[i])

export const getRouteIndex = (
  progressionDetail: PartialPartialPartial<ScenarioProgression>,
  scenario: Pick<Scenario, "routes">,
): number | undefined =>
  progressionDetail.initialEvaluation?.routeIndex ?? (scenario.routes.length === 1 ? 0 : undefined)

/** Get interactions for one static level, from /training/interaction */
export const getLevelInteractions = async (
  scenarioId: ScenarioId,
  levelId: LevelId,
  authParams?: AuthParams,
): Promise<BackInteraction[]> => {
  logger(`Getting interactions for level ${levelId}`)
  const result = throwIfApiError(
    await axios.get<{ results: BackInteraction[] }>(`${apiUrl}/training/interaction`, {
      params: {
        scenarioId,
        levelId,
        withoutMetadata: true,
        ...(authParams?.params ?? {}),
      },
      headers: authParams?.headers,
    }),
  )
  return result.results
}

export const getLevelInteractionsSafe = async (
  scenarioId: ScenarioId,
  levelId: LevelId,
  authParams?: AuthParams,
): Promise<Array<Interaction & Pick<TrainingInteraction, "stepId" | "trainingBlockId">>> => {
  try {
    return _(await getLevelInteractions(scenarioId, levelId, authParams))
      .uniqBy(i => i.timestamp)
      .map(levelInteraction => toInteraction(levelInteraction, false))
      .value()
  } catch (e) {
    logger(e)
    return []
  }
}
