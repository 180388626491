import type { ISOTime } from "./Evaluation"
import type { ResourceId } from "./Scenario"
import type { UniverseId, User, UserId } from "./User"

export const errorCodes = [
  "NO_ACTIVE_LICENSE_PACK",
  "ALREADY_HAVE_LICENSE",
  "NOT_ENOUGH_LICENSES",
  "DO_NOT_HAVE_LICENSE",
]

export type LicenseId = string

export interface ResourceSummary {
  packLicenseId: LicenseId
  universeId: UniverseId
  resourceId: ResourceId
  resourceName: string
  /** total number of licenses (included unassigned) */
  availableLicensesCount: number
}

export interface LicenseInfo {
  startDate: ISOTime
  endDate: ISOTime
  resourceId: ResourceId
  isDefinitive: boolean
  resourceName: string
}

export type UserWithLicenses = Pick<User, "firstName" | "lastName"> & {
  userId: UserId
  id: UserId
  email?: string
  divisionName: string
  licenseCount: number
  selectedLicense: LicenseInfo
  licenses: LicenseInfo[]
}
