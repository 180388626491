// noinspection JSUnusedGlobalSymbols

import type { BackIds } from "./Progression"
import type { Ids } from "./Scenario"
import { parseInt10 } from "./util"

export interface Memo extends Ids {
  text: string
  content?: string // TODO: remove this field when we align back and front
}

export interface BackMemo extends BackIds {
  content: string
}

export const toBackMemo = (memo: Memo): BackMemo => ({
  content: memo.text,
  ruleId: String(memo.ruleId),
  levelId: String(memo.levelId),
  moduleId: String(memo.moduleId),
})

export const toMemo = (memo: BackMemo): Memo => ({
  text: memo.content,
  content: memo.content,
  ruleId: parseInt10(memo.ruleId),
  levelId: parseInt10(memo.levelId),
  moduleId: parseInt10(memo.moduleId),
})
