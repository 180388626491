// noinspection JSUnusedGlobalSymbols

import { throwIfApiError } from "./ApiError"
import type { LevelDetails, LevelDetailsMin } from "./Level"
import { axiosCache, contentUrl } from "./local"
import obfuscation from "./obfuscation"
import type { HomeScenario, LevelId, Scenario, ScenarioId } from "./Scenario"

export const fetchAllScenarios = async (signal: AbortSignal | undefined): Promise<HomeScenario[]> =>
  throwIfApiError(
    await axiosCache.get(`${contentUrl}/scenarios/index.json`, {
      signal,
      ...obfuscation,
    }),
  )

export const fetchOneScenario = async (
  signal: AbortSignal | undefined,
  scenarioId?: ScenarioId | null,
): Promise<Scenario> =>
  throwIfApiError(
    await axiosCache.get(`${contentUrl}/scenarios/${scenarioId}.json`, {
      signal,
      ...obfuscation,
    }),
  )

export const fetchLevel = async <T extends LevelDetails = LevelDetails>(
  signal: AbortSignal | undefined,
  levelId?: LevelId,
): Promise<T> =>
  throwIfApiError(
    await axiosCache.get(`${contentUrl}/levels/${levelId}.json`, {
      signal,
      ...obfuscation,
    }),
  )

export const fetchLevelMin = async <T extends LevelDetailsMin = LevelDetailsMin>(
  signal: AbortSignal | undefined,
  levelId?: LevelId,
): Promise<T> =>
  throwIfApiError(
    await axiosCache.get(`${contentUrl}/min_levels/${levelId}.json`, {
      signal,
      ...obfuscation,
    }),
  )

export const fetchVersion = async (signal: AbortSignal | undefined): Promise<{ version: string }> =>
  throwIfApiError(
    await axiosCache.get(`${contentUrl}/version.json`, {
      signal,
      ...obfuscation,
    }),
  )

export interface Metadata {
  levelIds: number[]
  version: string
}

export const fetchMetadata = async <T extends Metadata = Metadata>(
  signal: AbortSignal | undefined,
): Promise<T> =>
  throwIfApiError(
    await axiosCache.get(`${contentUrl}/metadata.json`, {
      signal,
    }),
  )
