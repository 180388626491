// noinspection JSUnusedGlobalSymbols

import type { ISOTime } from "./Evaluation"
import type { UniverseId, UserId } from "./User"

export type DivisionId = string

export interface BackDivision {
  divisionId: DivisionId
  name: string
  universeId: UniverseId
  parentDivisionsIds: Array<{ id: DivisionId }>
  createdAt: ISOTime
  ownerId: UserId
  ownerEmail: string
  ownerFirstName: string
  ownerLastName: string
  nbLearners: number
}

export interface Universe {
  universeId: UniverseId
  name: string
  type?: "GAR" | "ED" | "QA"
}

export type Division = Omit<BackDivision, "divisionId"> & { id: DivisionId }
export const toDivision = (backDivision: BackDivision): Division => ({
  ...backDivision,
  id: backDivision.divisionId,
})
